.give-away-cont {
  background-color: var(--s);
  padding: 50px;
  display: flex;
  color: #fff;
}
.g-img,
.g-content {
  width: 50%;
}
.g-img img {
  width: 90%;
  border-radius: 15px;
  box-shadow: 0 3px 8px rgba(255, 255, 255, 0.3);
}
.g-content {
  background-color: var(--p);
  padding: 30px;
  border-radius: 15px;
}
.g-content h2 {
  color: var(--t);
  font-weight: 800;
}
.g-content ul li {
  margin: 10px 0;
}
.g-content ul li span {
  color: var(--t);
}

@media screen and (max-width: 992px) {
  .give-away-cont {
    padding: 50px 20px;
    flex-wrap: wrap;
    gap: 30px;
  }
  .g-img img {
    width: 100%;
  }
  .g-img,
  .g-content {
    width: 100%;
  }
}
