.noti-container {
  padding: 50px 20px;
  background-color: #fff;
  button {
    padding: 15px 20px;
    outline: transparent;
    border: transparent;
    margin-top: 5px;
    background-color: #000;
    color: #fff;
  }
}
