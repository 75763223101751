.hero-container {
  padding: 50px;
  overflow: hidden;
  position: relative;
  background-color: var(--s);
}
.hero-container img {
  border-radius: 10px;
  object-fit: cover;
  height: 220px;
}
.hero-slider-btns {
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 20px;
  z-index: 99;
  cursor: pointer;
}
.hero-slider-btns .icon {
  background-color: #000;
  color: #fff;
  padding: 10px;
  font-size: 50px;
  border-radius: 100px;
}
.hero-container .carousel-inner img {
  border-radius: 50px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}
.slick-slide {
  scale: 0.9;
  transition: 0.3s all ease;
}
.slick-center {
  scale: 1.1;
}
.slick-arrow {
  display: none !important;
}
.slick-dots li button:before {
  color: #fff !important;
}
.slick-dots {
  bottom: -20px !important;
}
@media screen and (max-width: 700px) {
  .hero-container {
    padding: 20px;
    padding-bottom: 40px;
  }
  .hero-slider-btns {
    display: none;
  }
  .hero-container img {
    height: 180px;
  }
}
